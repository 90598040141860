<template>
	<div>
		<TheHeader class="" />
		<div class="">
			<router-view></router-view>
		</div>
		<TheFooter />
		<WhatsAppBusiness />
	</div>
</template>

<script>
import TheFooter from '@/components/TheFooter.vue';
import TheHeader from '@/components/TheHeader.vue';
import WhatsAppBusiness from '@/components/WhatsAppBusiness.vue';

export default {
	name: 'DefaultLayout',
	components: { TheHeader, TheFooter, WhatsAppBusiness },
};
</script>

<style lang="scss" scoped></style>
