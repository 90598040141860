import Vue from 'vue'
import Vuex from 'vuex'
import bannerModule from './banner'
import courseModule from './course'
import categoryModule from './category'
import galleryModule from './gallery'
import testimonialModule from './testimonial'
import contactModule from './contact'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    banner: bannerModule,
    course: courseModule,
    category: categoryModule,
    gallery: galleryModule,
    testimonial: testimonialModule,
    contact: contactModule
  }
})
