import Vue from 'vue'
import VueRouter from 'vue-router'
import DefaultLayout from '../layouts/DefaultLayout.vue'
import goTo from 'vuetify/lib/services/goto'

Vue.use(VueRouter)
let  options = {
    duration: 1000,
    offset: 0,
    easing: 'easeInOutCubic'
};




const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0

    if (to.hash) {
      scrollTo = to.hash
    } else if (savedPosition) {
      scrollTo = savedPosition.y
    }

    return goTo(scrollTo, options)
  },
  routes: [
    {
      path: '/',
      component: DefaultLayout,
      children: [
        {
          path: '/',
          name: 'HomeView',
          component: () => import(/* webpackChunkName: "HomeView" */ '../views/HomeView.vue')
        },
        {
          path: '/marca',
          name: 'BrandView',
          component: () => import(/* webpackChunkName: "BrandView" */ '../views/BrandView.vue')
        },
        {
          path: '/contacto',
          name: 'ContactView',
          component: () => import(/* webpackChunkName: "ContactView" */ '../views/ContactView.vue')
        },
        {
          path: '/cursos',
          name: 'CourseView',
          component: () => import(/* webpackChunkName: "CourseView" */ '../views/CourseView.vue')
        },
        {
          path: '/galeria',
          name: 'GalleryCategoryView',
          component: () => import(/* webpackChunkName: "GalleryCategoryView" */ '../views//GalleryCategoryView.vue')
        },
        {
          path: '/salon',
          name: 'SalonView',
          component: () => import(/* webpackChunkName: "SalonView" */ '../views/SalonView.vue')
        },
        {
          path: '/servicios',
          name: 'SevicesView',
          component: () => import(/* webpackChunkName: "SevicesView" */ '../views/SevicesView.vue')
        },
        {
          path: '/servicios/novias',
          name: 'DetailSeviceNoviasView',
          component: () => import(/* webpackChunkName: "DetailSeviceNoviasView" */ '../views/services/DetailSeviceNoviasView.vue')
        },
        {
          path: '/servicios/xv-anios',
          name: 'DetailSeviceXVAniosView',
          component: () => import(/* webpackChunkName: "DetailSeviceXVAniosView" */ '../views/services/DetailSeviceXVAniosView.vue')
        },
        {
          path: '/servicios/social',
          name: 'DetailSeviceSocialView',
          component: () => import(/* webpackChunkName: "DetailSeviceSocialView" */ '../views/services/DetailSeviceSocialView.vue')
        },
        {
          path: '/servicios/tv-fotografia',
          name: 'DetailSeviceTVFotografiaView',
          component: () => import(/* webpackChunkName: "DetailSeviceTVFotografiaView" */ '../views/services/DetailSeviceTVFotografiaView.vue')
        },
        {
          path: '/galeria/:category',
          name: 'GalleryView',
          component: () => import(/* webpackChunkName: "GalleryView" */ '../views/GalleryView.vue'),
          meta: { title: 'Welcome' }
        },
        {
          path: '/galeria/:category/:gallery',
          name: 'GallerySlider',
          component: () => import(/* webpackChunkName: "GallerySlider" */ '../views/GallerySlider.vue')
        },
        // {
        //   path: '/galeria/:slug',
        //   name: 'DetailGalleryView',
        //   component: () => import(/* webpackChunkName: "DetailGalleryView" */ '../views/DetailGalleryView.vue')
        // },
      ]
    },
    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    // }
  ]
})

export default router
