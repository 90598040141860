<template>
	<v-footer padless class="footer">
		<div class="content mb-lg-10">
			<v-row
				class="justify-center justify-md-space-between mt-10 pb-2 pb-lg-0"
			>
				<!-- logo -->
				<v-col
					cols="10"
					sm="8"
					md="12"
					lg="auto"
					class="d-none d-md-block"
				>
					<router-link
						to="/"
						class="mb-6 mb-md-10 d-block mx-auto ml-lg-0 logo"
					>
						<img
							class=""
							src="../../public/iconos/logo.svg"
							alt="logo"
							height="100%"
							width="100%"
						/>
					</router-link>
					<div class="content-description">
						<p class="text-center text-lg-left">
							Maquillista profesional que desde pequeña ha
							mostrado pasión por la imagen, buen gusto y la
							belleza.
						</p>
					</div>
				</v-col>

				<!-- MENU -->
				<v-col
					cols="10"
					sm="8"
					md="4"
					lg="auto"
					class="pb-8 pb-lg-0 order-2 order-md-0"
					tag="address"
				>
					<h3 class="mb-5 text-center text-lg-left">Menú</h3>
					<ul class="">
						<li
							v-for="item in links"
							:key="item.id"
							class="d-flex align-center mb-5 justify-center justify-lg-start"
						>
							<router-link :to="{ name: item.to }">{{
								item.title
							}}</router-link>
						</li>
					</ul>
				</v-col>
				<!-- CONTACT -->
				<v-col
					cols="10"
					sm="8"
					md="4"
					lg="3"
					class="pb-8 pb-lg-0 order-0 order-md-1"
					tag="address"
				>
					<h3 class="mb-5 text-center text-lg-left">Contacto</h3>
					<ul class="">
						<li
							v-for="item in items"
							:key="item.id"
							class="d-flex mb-5 justify-center justify-lg-start"
						>
							<a
								:href="item.to"
								target="_blank"
								class="icon mr-2 mr-md-4 flex-grow-0"
							>
								<img
									:src="
										require(`../../public/iconos/${item.icon}.svg`)
									"
									:alt="`Logo de ${item.icon}`"
								/>
							</a>
							<div class="content-address" v-if="item.isAddress">
								<a
									href="https://www.google.com/maps/place/DISAZA/@20.659258,-103.4427882,15z/data=!4m2!3m1!1s0x0:0xb09fd6a27fde7b45?sa=X&ved=2ahUKEwi0uJrt4d2EAxXKhu4BHT0PCV4Q_BJ6BAgQEAA"
								>
									<p class="mb-0">{{ item.text }}</p>
								</a>
							</div>
							<a
								v-if="item.isEmail"
								:href="`mailto:${item.to}`"
								>{{ item.text }}</a
							>
							<a v-if="item.isPhone" :href="`tel::${item.to}`">{{
								item.text
							}}</a>
							<a v-if="item.isWhatsapp" :href="item.to">{{
								item.text
							}}</a>
						</li>
					</ul>
				</v-col>
				<!-- REDES -->
				<v-col
					cols="10"
					sm="8"
					md="4"
					lg="auto"
					class="pb-8 pb-lg-0 order-1 order-md-2"
					tag="address"
				>
					<h3 class="mb-5 text-center text-lg-left">Redes</h3>
					<ul class="">
						<li
							v-for="item in sociales"
							:key="item.id"
							class="d-flex align-center mb-5 justify-center justify-lg-start"
						>
							<a
								:href="item.to"
								target="_blank"
								class="icon mr-2 mr-md-4 flex-grow-0"
							>
								<img
									:src="
										require(`../../public/iconos/${item.icon}.svg`)
									"
									:alt="`Logo de ${item.icon}`"
								/>
							</a>
							<a :href="item.to" target="_blank">{{
								item.title
							}}</a>
						</li>
					</ul>
				</v-col>

				<!-- logo -->
				<v-col
					cols="10"
					sm="8"
					md="12"
					lg="auto"
					class="d-md-none order-3"
				>
					<router-link
						to="/"
						class="mb-6 mb-md-10 d-block mx-auto ml-lg-0 logo"
					>
						<img
							class=""
							src="../../public/iconos/logo.svg"
							alt="logo"
							height="100%"
							width="100%"
						/>
					</router-link>
					<div class="content-description">
						<p class="text-center text-lg-left">
							Maquillista profesional que desde pequeña ha
							mostrado pasión por la imagen, buen gusto y la
							belleza.
						</p>
					</div>
				</v-col>
			</v-row>
		</div>
		<v-row class="justify-center bottom align-center">
			<v-col cols="auto" class="">
				<small
					class="text-center d-flex flex-column flex-sm-row align-center"
				>
					© {{ getCurrenYear }} DISAZA
					<!-- <router-link :to="{ name: 'HomeView' }" class="">
						 DISAZA </router-link
					> -->

					. Todos los derechos reservados
				</small>
			</v-col>
		</v-row>
	</v-footer>
</template>

<script>
export default {
	data() {
		return {
			links: [
				{
					id: 1,
					title: 'Servicios',
					to: 'SevicesView',
				},
				{
					id: 2,
					title: 'Cursos',
					to: 'CourseView',
				},
				{
					id: 3,
					title: 'Galería',
					to: 'GalleryView',
				},
				{
					id: 4,
					title: 'Contacto',
					to: 'ContactView',
				},
				{
					id: 5,
					title: 'Salón',
					to: 'SalonView',
				},
			],
			sociales: [
				{
					id: 1,
					title: 'disaza.disaza',
					to: 'https://www.facebook.com/disaza.disaza',
					icon: 'facebook',
				},
				{
					id: 2,
					title: 'disazamakeup',
					to: 'https://www.instagram.com/disazamakeup/?hl=es-la',
					icon: 'instagram',
				},
			],
			items: [
				{
					id: 1,
					text: 'Av. Guadalupe 6450-B Col.Residencial Guadalupe 45030 Zapopan, Jal, Mx.',
					to: '',
					isEmail: false,
					isPhone: false,
					isWhatsapp: false,
					isAddress: true,
					icon: 'pin',
				},
				{
					id: 2,
					text: 'disazapaz@gmail.com',
					to: 'disazapaz@gmail.com',
					isEmail: true,
					isPhone: false,
					isWhatsapp: false,
					isAddress: false,
					icon: 'correo',
				},
				{
					id: 3,
					text: '(33) 2386 6504',
					to: '+523323866504',
					isEmail: false,
					isPhone: true,
					isWhatsapp: false,
					isAddress: false,
					icon: 'phone',
				},
				{
					id: 4,
					text: '(33) 2052 0077',
					to: 'https://wa.me/523320520077',
					isEmail: false,
					isPhone: false,
					isWhatsapp: true,
					isAddress: false,
					icon: 'whatsapp',
				},
			],
		};
	},
	computed: {
		getCurrenYear() {
			const date = new Date();
			return date.getFullYear();
		},
	},
};
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
@import '../assets/scss/partials/variables';

footer {
	background: linear-gradient(93deg, #303030 0%, #1c1c1c 99.61%) !important;
	position: relative !important;
	overflow: hidden !important;
	color: #ffffff;
	flex-direction: column;
}

h3 {
	color: #fff;
	font-family: 'Pacifico', cursive;
	font-size: $fs-lg;

	font-weight: 400;
	line-height: normal;
}
.content-description {
	max-width: 420px;
	margin: 0 auto;
}
.content-address {
	max-width: 320px;
}

a,
p {
	color: #fff !important;
	font-family: 'Work Sans';
	font-size: $fs-base;
	font-style: normal;
	font-weight: 300;
}
p {
	line-height: 200.3%; /* 0px */
}

a {
	&:hover {
		// color: $primary;
		// opacity: 0.6;
		text-decoration: underline;
	}
}

.logo {
	width: 238.229px;
	height: 76.439px;
}

.icon {
	width: 14px !important;
	min-width: 14px !important;
	height: 14px !important;
	display: block;
	img {
		width: 100%;
		height: 100%;
	}
}

.bottom {
	height: 60px;
	background: #202020;
	width: 100%;
	small,
	a {
		color: #fff;
		font-family: 'Work Sans';
		font-size: 12px;

		font-weight: 300;
		line-height: normal;
	}
	a {
		text-transform: uppercase;
	}
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
	.icon {
		width: 16px !important;
		min-width: 16px !important;
		height: 16px !important;
	}
}
@media #{map-get($display-breakpoints, 'md-and-up')} {
	.bottom {
		height: 40px;
	}

	.logo {
		width: 252.443px;
		height: 81px;
	}
}
@media #{map-get($display-breakpoints, 'lg-and-up')} {
	.content-description {
		max-width: 320px;
		margin: 0 auto;
	}
}
</style>
