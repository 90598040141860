<template>
	<div class="fill-height" style="">
		<div class="fill-height nav-siderbar">
			<ul class="mt-6 pt-16 pt-lg-0 mt-lg-0 fill-height">
				<li
					class="mb-10 px-lg-2 px-lg-6 mb-lg-0 d-block text-center d-lg-flex flex-column"
					v-for="(item, index) in items"
					:key="index"
				>
					<router-link
						class="text-center"
						:to="{ name: item.to }"
						@click.native="closeMenu"
					>
						<span>{{ item.title }}</span>
					</router-link>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			shoMenu: false,
			items: [
				{ title: 'Home', to: 'HomeView' },
				{ title: 'Marca', to: 'BrandView' },
				{ title: 'Servicios', to: 'SevicesView' },
				{ title: 'Cursos', to: 'CourseView' },
				{ title: 'Galería', to: 'GalleryCategoryView' },
				{ title: 'Salón', to: 'SalonView' },
				{ title: 'Contacto', to: 'ContactView' },
			],
		};
	},
	methods: {
		closeMenu() {
			this.$emit('closeMenu');
		},
		showSubMenuMovil() {
			console.log('mostrar w');
			this.$emit('show');
		},
	},
};
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
@import '../assets/scss/partials/variables';
.nav-siderbar {
	ul {
		padding: 0 !important;
		li {
			display: flex;
			justify-content: center;
			a {
				color: #fff !important;
				text-align: right !important;
				// font-family: Roboto !important;
				font-size: $fs-base !important;
				font-style: normal !important;
				font-weight: 300 !important;
				line-height: normal !important;
				letter-spacing: 0.4px !important;
				&:hover {
					// color: $primary;
				}
			}
		}
	}
}
.router-link-exact-active.router-link-active {
	color: #b79a4b !important;
	// font-family: Roboto !important;
	font-size: $fs-base !important;
	font-weight: 600 !important;
}

// @media screen and (min-width: $md) {
//   .nav {
//     position: fixed;
//     width: 100%;
//     max-width: 425px;
//     height: 100%;
//     right: 0;
//     left: auto;
//     // top: 55px;
//   }
// }
@media #{map-get($display-breakpoints, 'lg-and-up')} {
	.nav-siderbar {
		ul {
			display: flex !important;
			align-items: center !important;
			height: 100%;
			li {
				display: flex;
				justify-content: space-between;
				height: 100%;
				a {
					// display: flex;
					// align-items: center;
					//   font-size: 13px;
					// height: 100%;
					// padding-bottom: 30px;
					// border-bottom: 2px solid red;
					&:hover {
						// color: $primary;
					}
				}
			}
		}
	}
}
</style>
