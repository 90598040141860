export default {
	namespaced: true,

	state: {
		all: [],
		model: null,
		isReady: false,
	},

	mutations: {
		setAll(state, all) {
			state.all = all;
		},

		setModel(state, model) {
			state.model = model;
		},
		setREady(state, value) {
			state.isReady = value;
		},
	},

	actions: {
		loadAll({ commit }, payload = {}) {
			commit('setREady', false);
			let params = {};

			if (payload.scopes) {
				params.scopes = payload.scopes;
			}

			if (payload.appends) {
				params.appends = payload.appends;
			}

			return this._vm.$axios
				.get('models/galleries/all', {
					params: params,
				})
				.then((response) => {
					commit('setAll', response.data.data);
					commit('setREady', true);

					return response.data.data;
				});
		},

		loadModel({ commit }, payload = {}) {
			let params = {};

			if (payload.scopes) {
				params.scopes = payload.scopes;
			}

			if (payload.appends) {
				params.appends = payload.appends;
			}

			return this._vm.$axios
				.get(`galleries/${payload.resource}`, {
					params: params,
				})
				.then((response) => {
					commit('setModel', response.data.data);

					return response.data.data;
				});
		},
	},

	getters: {
		orderAllByIdAsc(state) {
			return state.all.sort((a, b) => a.id - b.id);
		},
		getIsReady(state) {
			return state.isReady;
		},
	},
};
