export default {
    namespaced: true,

    state: {
        all: []
    },

    mutations: {
        setAll(state, all) {
            state.all = all
        }
    },

    actions: {
        loadAll({ commit }) {
            return this._vm.$axios.get('models/courses/all')
                .then(response => {
                    commit(
                        'setAll',
                        response.data.data
                    )
                    
                    return response.data.data
                })
        }
    }
}