<template>
	<nav>
		<v-app-bar
			:class="{ sticky: sticky }"
			:color="bgColor"
			app
			fixed
			flat
			class="nav"
		>
			<div class="content fill-height">
				<v-row
					class="justify-space-between align-center overflow-hidden fill-height"
				>
					<!-- VACIO -->
					<v-col cols="auto" class="hidden-lg-and-up order-0 py-0">
					</v-col>
					<!-- LOGO -->
					<v-col
						cols="auto"
						class="order-0 order-lg-0 d-flex align-center py-0"
					>
						<router-link :to="{ name: 'HomeView' }" class="d-block">
							<img
								class="logo"
								src="../../public/iconos/logo.svg"
								alt="logo-disaza"
							/>
						</router-link>
					</v-col>
					<!-- MENU -->
					<v-col cols="auto" class="hidden-lg-and-up order-0 py-0">
						<v-btn
							@click.stop="openMenu"
							depressed
							fab
							small
							color="#fff"
							text
						>
							<img
								class="menu"
								src="../../public/iconos/menul.svg"
								alt="menu-burger"
							/>
						</v-btn>
					</v-col>
					<v-col cols="" lg="auto" class="py-0 hidden-md-and-down">
						<ListMenu />
					</v-col>
				</v-row>
			</div>
		</v-app-bar>

		<v-navigation-drawer
			class="drawer hidden-lg-and-up"
			v-model="showMenu"
			fixed
		>
			<v-btn
				icon
				color="primary"
				absolute
				top
				right
				@click="showMenu = false"
			>
				<img
					src="../../public/iconos/cerrar.svg"
					alt="cerrar"
					width="18px"
					height="18px"
				/>
			</v-btn>
			<v-row class="align-center justify-center">
				<!-- LOGO -->
				<v-col cols="12" class="d-flex align-center">
					<router-link
						:to="{ name: 'HomeView' }"
						class="d-block mx-auto"
					>
						<img
							class="logo"
							src="../../public/iconos/logo.svg"
							alt="logo-disaza"
						/>
					</router-link>
				</v-col>
				<v-col cols="12" class="py-0">
					<v-divider style="background: #303030"></v-divider>
				</v-col>
				<v-col cols="12">
					<ListMenu />
				</v-col>
			</v-row>
			<!-- <v-row class="mx-4">
				<v-col cols="12" class="pt-0">
					<v-divider style="background: #0d3b7c"></v-divider>
				</v-col>
			</v-row> -->

			<!-- <SubMenMovil /> -->
		</v-navigation-drawer>
	</nav>
</template>

<script>
import ListMenu from './ListMenu.vue';
export default {
	props: {
		solid: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			sticky: false,
			showMenu: false,
		};
	},
	beforeMount() {
		window.addEventListener('scroll', this.handleScroll);
	},
	computed: {
		bgColor() {
			if (this.$vuetify.breakpoint.mdAndDown || this.solid) {
				return '#fff';
			} else {
				return 'transparent';
			}
		},
	},
	methods: {
		openMenu() {
			this.showMenu = !this.showMenu;
		},
		close() {
			this.showMenu = false;
		},
		handleScroll() {
			if (window.scrollY > 10) {
				this.sticky = true;
			} else {
				this.sticky = false;
			}
		},
	},
	components: { ListMenu },
};
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

// * {
// 	border: 1px solid red;
// }
nav {
	z-index: 80;
	position: relative;
}

.nav {
	// box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18) !important;
	min-height: 56.622px !important;
	top: 0;
	// border: 1px solid orange !important;
	// background-color: orange !important;
	background: rgba(0, 0, 0, 0.5) !important;
	// background-color: !important;
	// min-height: 47px;
}

.menu {
	width: 15px;
	height: 15px;
}

.logo {
	width: 100.975px;
	height: 40.579px;
}

.drawer {
	// opacity: 0.95;
	// background-color: black !important;
	max-width: 425px !important;
	width: 100% !important;
	background: rgba(21, 21, 21, 0.9);
	// opacity: 0.5;
}
// .nav.sticky {
// 	background-color: #b79a4b !important;
// 	// position: fixed;
// }
.nav.sticky {
	background: rgba(0, 0, 0, 1) !important;
	// position: fixed;
}
@media #{map-get($display-breakpoints, 'md-and-up')} {
	.logo {
		width: 159px;
		height: 63px;
	}
	.nav {
		min-height: 100px !important;
	}
}
</style>
